/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
/* eslint-disable guard-for-in */
import { fixFontsUrl } from '../helpers';

function getLayerType(layers, layerId) {
  if (layers.length !== 0) {
    for (const layer of layers) {
      if (layer.id === layerId) {
        return layer.type;
      }
    }
  }
}
function getLayerChildren(layers, layerId) {
  if (layers.length !== 0) {
    for (const layer of layers) {
      if (layer.id === layerId) {
        return layer.layers;
      }
    }
  }
}

function updateAlignment(layer) {
  if (
    ('text_alignment' in layer.format || 'text_align' in layer.format) &&
    !('align_items' in layer.format)
  ) {
    // let updatedFormat = layer.format;
    if (layer.format.text_alignment === 'center' || layer.format.text_align === 'center') {
      layer.format = {
        ...layer.format,
        align_items: 'center',
        display: 'flex',
        flex_flow: 'column',
        justify_content: 'start'
      };
    }
    if (layer.format.text_alignment === 'left' || layer.format.text_align === 'left') {
      layer.format = {
        ...layer.format,
        align_items: 'start',
        display: 'flex',
        flex_flow: 'column',
        justify_content: 'start'
      };
    }
    if (layer.format.text_alignment === 'right' || layer.format.text_align === 'right') {
      layer.format = {
        ...layer.format,
        align_items: 'end',
        display: 'flex',
        flex_flow: 'column',
        justify_content: 'start'
      };
    }
  }
  return layer;
}
function updateTextAlignment(template) {
  const { formats, object_data } = template;
  if (formats) {
    for (const format of formats) {
      if (!('object_data' in format)) format.object_data = {};
      if (!('layers' in format.object_data)) format.object_data.layers = {};

      for (const layerId in format.object_data.layers) {
        const layer = format.object_data.layers[layerId];
        const layerType = getLayerType(object_data.layers, layerId);

        if (layerType === 'text' && layer.format !== undefined) {
          updateAlignment(layer);
        }
        if (layerType === 'group') {
          const layers = getLayerChildren(object_data.layers, layerId);
          layers?.forEach((childLayer) => {
            if (childLayer.type === 'text' && childLayer.format !== undefined) {
              updateAlignment(childLayer);
            }
          });
        }
      }
    }
    object_data.layers &&
      object_data.layers.forEach((layer) => {
        if (layer.type === 'text' && layer.format !== undefined) {
          updateAlignment(layer);
        }
        if (layer.type === 'group') {
          layer.layers?.forEach((layer) => {
            if (layer.type === 'text' && layer.format !== undefined) {
              updateAlignment(layer);
            }
          });
        }
      });
  }
  return {
    ...template,
    formats,
    object_data: {
      ...object_data
    }
  };
}
function createColorData(colorString) {
  // this is handling old templates border missing color key
  if (colorString === undefined) {
    return { color: 'rgb(255, 255, 255)', a: 1 };
  }
  if (colorString.startsWith('#')) {
    return { color: colorString, a: 1 };
  }
  if (colorString.startsWith('rgba')) {
    const [r, g, b, a] = colorString
      .substring(colorString.indexOf('(') + 1, colorString.lastIndexOf(')'))
      .split(/,\s*/);
    return { color: `rgb(${r}, ${g}, ${b})`, a };
  }
  if (colorString === '') {
    return '';
  }
}

function callUpgrader(layer) {
  if ('background_color' in layer.format && typeof layer.format.background_color === 'string') {
    const backgroundColor = createColorData(layer.format.background_color);

    layer.format = {
      ...layer.format,
      background_color:
        // make backgroundColor undefined on image layers
        backgroundColor !== '' && layer.type !== 'image'
          ? {
              palette: [
                {
                  color: backgroundColor.color || 'rgb(255, 255, 255)',
                  opacity: backgroundColor.a || 1,
                  offset: '0'
                }
              ],
              angle: 90,
              colorType: 'solid'
            }
          : undefined
    };
  }
  if (
    'border' in layer.format &&
    'color' in layer.format.border &&
    typeof layer.format.border.color === 'object'
  ) {
    layer.format = {
      ...layer.format,
      border: { ...layer.format.border, color: 'rgba(255, 255, 255,1)' }
    };
  }
  return layer;
}

function updateBackgroundAndBorderColor(template) {
  const { formats, object_data } = template;
  if (formats) {
    for (const format of formats) {
      if (!('object_data' in format)) format.object_data = {};
      if (!('layers' in format.object_data)) format.object_data.layers = {};

      for (const layerId in format.object_data.layers) {
        const layer = format.object_data.layers[layerId];
        const layerType = getLayerType(object_data.layers, layerId);
        if (layer.format !== undefined) {
          callUpgrader(layer);
        }

        if (layerType === 'group') {
          const layers = getLayerChildren(object_data.layers, layerId);
          layers?.forEach((childLayer) => {
            if (childLayer.format !== undefined) {
              callUpgrader(childLayer);
            }
          });
        }
      }
    }
    if (object_data.layers) {
      object_data.layers.forEach((layer) => {
        if (layer.format !== undefined) {
          callUpgrader(layer);
        }

        if (layer.type === 'group') {
          layer.layers?.forEach((childLayer) => {
            if (childLayer.format !== undefined) {
              callUpgrader(childLayer);
            }
          });
        }
      });
    }
  }
  return {
    ...template,
    formats,
    object_data: {
      ...object_data
    }
  };
}

function upgradeFlipAnimation(template) {
  const { object_data } = template;

  if (object_data.layers) {
    object_data.layers.forEach((layer) => {
      if (layer.animations && layer.animations.length > 0) {
        const { animations } = layer;

        animations.forEach((animation) => {
          if (animation.type === 'flip') {
            animation.type = 'flipY';

            animation.settings = {
              ...animation.settings,
              transformOrigin: 'center center'
            };
          }
        });
      }
    });
  }
}

function upgradeAssetsOnFormats(template) {
  const { object_data, formats } = template;
  if (formats) {
    for (const format of formats) {
      if (!format.object_data.images) format.object_data.images = [];
      if (!format.object_data.videos) format.object_data.videos = [];
      if (!format.object_data.fonts) format.object_data.fonts = [];

      if (object_data.images) {
        format.object_data.images = format.object_data.images.concat(object_data.images);
      }
      if (object_data.videos) {
        format.object_data.videos = format.object_data.videos.concat(object_data.videos);
      }
      if (object_data.fonts) {
        format.object_data.fonts = format.object_data.fonts.concat(object_data.fonts);
      }
    }
  }
  if (template.object_data.settings?.linked_clicktags === undefined) {
    if (template.object_data.settings === undefined) {
      template.object_data.settings = {};
    }
    template.object_data.settings.linked_clicktags = true;
  }
  delete template.object_data.images;
  delete template.object_data.videos;
  delete template.object_data.fonts;

  // We're checking for layer image sources to attempt to fix old creative sets that have been migrated and something has gone wrong in the process.
  const checkLayersSources = (layers) => {
    if (layers) {
      for (const layer of layers) {
        if (layer.type === 'image') {
          for (const format of formats) {
            const layerFormat = format.object_data.layers[layer.id];
            if (layerFormat) {
              const imageSource = layerFormat.settings?.source ?? layer.settings?.source;
              if (imageSource) {
                const layerImage = format.object_data.images.find(
                  (image) => image.uuid === imageSource
                );
                if (!layerImage && format.object_data.images.length > 0) {
                  format.object_data.images.push({
                    uuid: imageSource,
                    source: format.object_data.images[0].source.replace(
                      /[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/i,
                      imageSource
                    )
                  });
                }
              }
            }
          }
        }
        if (layer.layers) {
          checkLayersSources(layer.layers);
        }
      }
    }
  };
  checkLayersSources(object_data.layers);
}

function upgradeFontsUrl(template) {
  const { formats } = template;
  // we don't need to update the fonts on the template because they are deleted in the previous step
  if (formats) {
    for (const format of formats) {
      if (format.object_data.fonts !== undefined && format.object_data.fonts.length > 0) {
        format.object_data.fonts = fixFontsUrl(format.object_data.fonts);
      }
    }
  }
}
function upgradeAnimationEasing(template) {
  const layerLoop = (layers) => {
    for (const layer of layers) {
      if (layer.animations) {
        for (const animation of layer.animations) {
          if (typeof animation.ease === 'string') {
            animation.ease = {
              type: 'core',
              preset: animation.ease,
              hasEasingType: animation.ease !== 'none' && animation.ease !== 'steps(12)'
            };
          }
        }
      }
      if (layer.layers) {
        layerLoop(layer.layers);
      }
    }
  };
  if (template.object_data.layers) {
    layerLoop(template.object_data.layers);
  }
}

export function upgradeTemplate(template) {
  const { formats, object_data } = template;

  if (!object_data) return template;

  // Upgrade from "0" to "1" - Update: Add template_version to templates
  if (!('template_version' in object_data)) {
    object_data.template_version = 1;
  }

  // Upgrade from "1" to "2" - Update: Add image editor imageStates
  if (object_data.template_version === 1) {
    for (const format of formats) {
      if (!('object_data' in format)) format.object_data = {};
      if (!('layers' in format.object_data)) format.object_data.layers = {};

      for (const layerId in format.object_data.layers) {
        const layer = format.object_data.layers[layerId];
        const layerType = getLayerType(object_data.layers, layerId);

        if (layerType === 'image') {
          if (!('settings' in layer)) layer.settings = {};
          if (!('imageState' in layer.settings)) {
            if ('format' in layer) {
              layer.settings.imageState = {
                /* crop: {
                  x: 0,
                  y: 0,
                  height: layer.format.height,
                  width: layer.format.width
                }, */
                cropAspectRatio: layer.format.width / layer.format.height,
                targetSize: {
                  width: layer.format.width,
                  height: layer.format.height
                }
              };
            }
          }
        }
      }
    }

    object_data.template_version = 2;
  }
  if (object_data.template_version === 2) {
    updateTextAlignment(template);
    object_data.template_version = 3;
  }
  if (object_data.template_version === 3) {
    updateBackgroundAndBorderColor(template);
    object_data.template_version = 4;
  }
  if (object_data.template_version === 4) {
    upgradeFlipAnimation(template);
    object_data.template_version = 5;
  }
  if (object_data.template_version === 5) {
    upgradeAssetsOnFormats(template);
    object_data.template_version = 6;
  }
  if (object_data.template_version === 6) {
    upgradeFontsUrl(template);
    object_data.template_version = 7;
  }
  if (object_data.template_version === 7) {
    upgradeAnimationEasing(template);
    object_data.template_version = 8;
  }
  return {
    ...template,
    formats,
    object_data: {
      ...object_data
    }
  };
}
